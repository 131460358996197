
const SET_DEVICE_TRACK_DATA = 'SET_DEVICE_TRACK_DATA'

export const setDeviceTrackDataAction = data => ({
  type: SET_DEVICE_TRACK_DATA,
  payload: { data }
})


const initialState = {
  deviceTrackData: undefined
}

export default function appDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_TRACK_DATA:
      return {
        ...state,
        deviceTrackData: action.payload.data
      }
    default:
      return state
  }
}