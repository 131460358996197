import { apiCallStartAction, apiCallFinishAction } from "../reducers/apiCall";
import axios from "axios";
import HttpStatus from "http-status-codes";
import {
  showError,
  generateApiUrl,
  history,
  showSuccess,
} from "../utils/service";
import { takeLatest, call, put, all, delay } from "redux-saga/effects";
import { API_LIST } from "../utils/api";
import {
  accountLoginAction,
  accountRefreshByAccessTokenAction,
  accountLogoutAction,
} from "../reducers/entity/account";
import {
  setUserListAction,
  deleteUserDataAction,
  addOrUpdateUserListAction,
} from "../reducers/entity/users";
import { setDeviceTrackDataAction} from "../reducers/appData";
import { setConfigurationDataAction } from "../reducers/entity/configuration";
import { setEditConfigurationDataAction } from "../reducers/ui/configuration";

import {
    setThreatListsAction,
    setThreatListAction,
    deleteThreatListAction,
} from "../reducers/entity/threatList";

import {
    setThreatItemsAction
} from "../reducers/entity/threatItems"

function* callAPI(api, apiCallParams = {}, apiURLParams, queryParams) {
  yield put(apiCallStartAction(api.ID));

  queryParams = queryParams ?  queryParams: {};

  let result = {
    statusCode: 0,
    data: "",
  };
  try {
    const apiUrl = generateApiUrl(api.URL, apiURLParams);
    const { data } = yield call(axios, {
      method: api.METHOD,
      url: apiUrl,
      data: apiCallParams,
      params: queryParams
    });
    result.statusCode = HttpStatus.OK;
    result.data = data;
  } catch (err) {
    const { response } = err;

    let errMsg = "";

    if (undefined === response) {
      result.statusCode = HttpStatus.SERVICE_UNAVAILABLE;
      result.message = "Server is not available";

      errMsg = result.message;
    } else {
      result.statusCode = response.status;
      result.data = response.data;

      switch (response.status) {
        case HttpStatus.UNAUTHORIZED:
          errMsg = "Session is expired.\nPlease login.";
          yield put(accountLogoutAction());
          break;
        case HttpStatus.INTERNAL_SERVER_ERROR:
          errMsg =
            "Error happened on the server.\nPlease contact administrator.";
          break;
        case HttpStatus.NOT_FOUND:
          errMsg = "Not found";
          break;
        case HttpStatus.BAD_REQUEST:
          errMsg = response.data.message;
          break;
      }
    }

    if ("" !== errMsg) {
      showError(errMsg);
    }
  }

  yield put(apiCallFinishAction(api.ID, result.statusCode));
  return result;
}

function* loadApplicationData() {
  let { data, statusCode } = yield callAPI(API_LIST.GET_USER_LIST);
  if (HttpStatus.OK === statusCode) {
    yield put(setUserListAction(data));
  }
}

// Load Configuration
export const apiLoadConfigurationAction = () => ({
  type: API_LIST.LOAD_CONFIGURATION.ID,
});
function* apiLoadConfigurationEffect() {
  let { data, statusCode } = yield callAPI(API_LIST.LOAD_CONFIGURATION);

  if (HttpStatus.OK === statusCode) {
    yield put(setConfigurationDataAction(data));
    yield put(setEditConfigurationDataAction(JSON.parse(JSON.stringify(data))));
  }
}

// Update Configuration
export const apiUpdateConfigurationAction = (updateData, callback) => ({
  type: API_LIST.UPDATE_CONFIGURATION.ID,
  payload: { updateData, callback },
});
function* apiUpdateConfigurationEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.UPDATE_CONFIGURATION,
    action.payload.updateData
  );

  if (HttpStatus.OK === statusCode) {
    yield call(action.payload.callback);
    yield put(setConfigurationDataAction(data));
    yield put(setEditConfigurationDataAction(JSON.parse(JSON.stringify(data))));
  }
}

// User Login
export const apiLoginUserAction = (loginCredential) => ({
  type: API_LIST.LOGIN.ID,
  payload: { loginCredential },
});
function* apiLoginUserEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.LOGIN,
    action.payload.loginCredential
  );

  if (HttpStatus.OK === statusCode) {
    yield put(accountLoginAction(data.token, data.user));
    yield loadApplicationData();

    history.push("/");
  }
}

// User Logout
export const apiLogoutUserAction = () => ({
  type: API_LIST.LOGOUT.ID,
});
function* apiLogoutUserEffect() {
  yield callAPI(API_LIST.LOGOUT);
  yield put(accountLogoutAction());

  history.push("/");
}

// Delete User
export const apiDeleteUserAction = (userId) => ({
  type: API_LIST.DELETE_USER.ID,
  payload: { userId },
});
function* apiDeleteUserEffect(action) {
  let { statusCode } = yield callAPI(
    API_LIST.DELETE_USER,
    {},
    action.payload.userId
  );
  if (HttpStatus.OK === statusCode) {
    yield put(deleteUserDataAction(action.payload.userId));
  }
}

// Update User
export const apiUpdateUserAction = (userId, userData) => ({
  type: API_LIST.UPDATE_USER.ID,
  payload: { userId, userData },
});
function* apiUpdateUserEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.UPDATE_USER,
    action.payload.userData,
    action.payload.userId
  );

  if (HttpStatus.OK === statusCode) {
    yield put(addOrUpdateUserListAction([data]));
  }
}

// Send Password Recovery Mail
export const apiSendPasswordRecoveryMailAction = (email) => ({
  type: API_LIST.SEND_RECOVERY_MAIL.ID,
  payload: { email },
});
function* apiSendPasswordRecoveryMailEffect(action) {
  const { statusCode } = yield callAPI(
    API_LIST.SEND_RECOVERY_MAIL,
    action.payload
  );

  if (HttpStatus.OK === statusCode) {
    showSuccess("recovery mail was sent.");
  }
}

// Reset Password
export const apiPasswordResetAction = (token, password) => ({
  type: API_LIST.PASSWORD_RESET.ID,
  payload: { token, password },
});
function* apiPasswordResetEffect(action) {
  const { statusCode } = yield callAPI(API_LIST.PASSWORD_RESET, action.payload);

  if (HttpStatus.OK === statusCode) {
    history.push("/pages/login-boxed");
  }
}

// Get Device Track Data
export const apiGetDeviceTrackDataAction = (
  first_name,
  last_name,
  email,
  device,
  ip,
  record_start,
  record_end,
  page,
  page_size
) => ({
  type: API_LIST.GET_DEVICE_TRACK_DATA.ID,
  payload: {
    first_name,
    last_name,
    email,
    device,
    ip,
    record_start,
    record_end,
    page,
    page_size,
  },
});
function* apiGetDeviceTrackDataEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.GET_DEVICE_TRACK_DATA,
    action.payload
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setDeviceTrackDataAction(data));
  }
}



export const apiGetThreatListDataAction = (
    title,
    url,
    type,
    active
) => ({
  type: API_LIST.GET_THREAT_LIST_DATA.ID,
  payload: {
    title,
    url,
    type,
    active
  },
});



export const apiUpdateThreatListAction = (
    threatListId,
    threatListData
) => ({
  type: API_LIST.UPDATE_THREAT_LIST_DATA.ID,
  payload: { threatListId, threatListData},
});

function* apiUpdateThreatListEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.UPDATE_THREAT_LIST_DATA,
    action.payload.threatListData,
    action.payload.threatListId
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setThreatListAction(data));
  }
}


export const apiCreateThreatListAction = (
    threatListData
) => ({
        type: API_LIST.CREATE_THREAT_LIST_DATA.ID,
        payload: {threatListData}
    });


function* apiCreateThreatListEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.CREATE_THREAT_LIST_DATA,
    action.payload.threatListData,
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setThreatListAction(data));
  }
}


export const apiDeleteThreatListAction = (
    threatListId,
) => ({
  type: API_LIST.DELETE_THREAT_LIST_DATA.ID,
  payload: { threatListId,},
});

function* apiDeleteThreatListEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.DELETE_THREAT_LIST_DATA,
    {},
    action.payload.threatListId
  );

  if (HttpStatus.OK === statusCode) {
    yield put(deleteThreatListAction(action.payload.threatListId));
  }
}

function* apiGetThreatListEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.GET_THREAT_LIST_DATA,
    action.payload
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setThreatListsAction(data));
  }
}


export const apiListThreatItemsAction = (
    page
) => (
{
    type: API_LIST.LIST_THREAT_ITEMS.ID,
    queryParams:  {page}
});


function* apiListThreatItemsEffect(action){
  let { data, statusCode } = yield callAPI(
        API_LIST.LIST_THREAT_ITEMS,
        {}, {},
        action.queryParams,
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setThreatItemsAction(data));
  }
}


// Load App Data
const API_LOAD_APP_DATA = "API_LOAD_APP_DATA";
export const apiLoadAppDataAction = () => ({
  type: API_LOAD_APP_DATA,
});
function* apiLoadAppDataEffect(action) {
  let { data, statusCode } = yield callAPI(API_LIST.GET_ACCOUNT_DATA);

  if (HttpStatus.OK === statusCode) {
    yield put(accountRefreshByAccessTokenAction(data));

    yield loadApplicationData();
  }
}

export default function* apiCallSaga() {
  yield all([
    takeLatest(API_LIST.LOGIN.ID, apiLoginUserEffect),
    takeLatest(API_LIST.LOGOUT.ID, apiLogoutUserEffect),
    takeLatest(
      API_LIST.SEND_RECOVERY_MAIL.ID,
      apiSendPasswordRecoveryMailEffect
    ),
    takeLatest(API_LIST.PASSWORD_RESET.ID, apiPasswordResetEffect),

    takeLatest(API_LOAD_APP_DATA, apiLoadAppDataEffect),

    takeLatest(API_LIST.GET_DEVICE_TRACK_DATA.ID, apiGetDeviceTrackDataEffect),
    takeLatest(API_LIST.GET_THREAT_LIST_DATA.ID, apiGetThreatListEffect),
    takeLatest(API_LIST.UPDATE_THREAT_LIST_DATA.ID, apiUpdateThreatListEffect),
    takeLatest(API_LIST.CREATE_THREAT_LIST_DATA.ID, apiCreateThreatListEffect),
    takeLatest(API_LIST.DELETE_THREAT_LIST_DATA.ID, apiDeleteThreatListEffect),

    takeLatest(API_LIST.LIST_THREAT_ITEMS.ID, apiListThreatItemsEffect),

    takeLatest(API_LIST.UPDATE_USER.ID, apiUpdateUserEffect),
    takeLatest(API_LIST.DELETE_USER.ID, apiDeleteUserEffect),

    takeLatest(API_LIST.LOAD_CONFIGURATION.ID, apiLoadConfigurationEffect),
    takeLatest(API_LIST.UPDATE_CONFIGURATION.ID, apiUpdateConfigurationEffect),
  ]);
}
