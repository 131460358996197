const SET_THREAT_ITEMS = "SET_THREAT_ITEMS";

export const setThreatItemsAction = (threatItems) => ({
    type: SET_THREAT_ITEMS,
    payload: {
        threatItems,
    },
});

const initialState = [];

export default function entitytThreatListReducer(state = initialState, action) {
    switch (action.type) {
        case SET_THREAT_ITEMS:
            return action.payload.threatItems
        default:
            return state;
    }
}
