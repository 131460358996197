
const SET_EDIT_CONFIGURATION_DATA = 'SET_EDIT_CONFIGURATION_DATA'
const SET_EDIT_CONFIGURATION_FIELD = 'SET_EDIT_CONFIGURATION_FIELD'

export const setEditConfigurationDataAction = data => ({
  type: SET_EDIT_CONFIGURATION_DATA,
  payload: { data }
})

export const setEditConfigurationFieldAction = (field, value) => ({
  type: SET_EDIT_CONFIGURATION_FIELD,
  payload: { field, value }
})

const initialState = {
  editConfigurationData: {

  },
}

export default function uiConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_CONFIGURATION_FIELD: {
      let { editConfigurationData } = state
      editConfigurationData[action.payload.field] = action.payload.value
      return {
        ...state,
        editConfigurationData,
      }
    }
    case SET_EDIT_CONFIGURATION_DATA:
      return {
        ...state,
        editConfigurationData: action.payload.data
      }
    default:
      return state
  }
}