
const SET_CONFIGURATION_DATA = 'SET_CONFIGURATION_DATA'

export const setConfigurationDataAction = data => ({
  type: SET_CONFIGURATION_DATA,
  payload: { data }
})

const initialState = {
  configuration: undefined
}

export default function entityConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIGURATION_DATA:
      return {
        ...state,
        configuration: action.payload.data
      }
    default:
      return state
  }
}