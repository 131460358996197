
const START_USER_EDIT = 'START_USER_EDIT'
const STOP_USER_EDIT = 'STOP_USER_EDIT'
const SET_EDIT_USER_FIELD = 'SET_EDIT_USER_FIELD'

export const startUserEditAction = userInfo => ({
  type: START_USER_EDIT,
  payload: {
    userInfo,
  }
})

export const setEditUserFieldAction = (field, value) => ({
  type: SET_EDIT_USER_FIELD,
  payload: {
    field,
    value,
  }
})

export const stopUserEditAction = () => ({
  type: STOP_USER_EDIT,
  payload: {}
})

const initialState = {
  isEditing: false,
  editUserInfo: {},
}

export default function uiUsersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_USER_FIELD: {
      let { editUserInfo } = state
      editUserInfo[action.payload.field] = action.payload.value 
      return {
        ...state,
        editUserInfo,
      }
    }
    case STOP_USER_EDIT:
      return {
        ...state,
        isEditing: false,
      }
    case START_USER_EDIT:
      return {
        ...state,
        isEditing: true,
        editUserInfo: action.payload.userInfo
      }
    default:
      return state
  }
}