import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  let middlewares = applyMiddleware(sagaMiddleware)
  let store = createStore(
    rootReducer,
    composeWithDevTools(middlewares),
  )

  sagaMiddleware.run(rootSaga);

  return store;
}