export const API_ROOT = window.__BACKEND_URL__ + "/api";

const HTTP_METHOD = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

export const API_CALL_STATE = {
    UNKNOWN: 0,
    PROCESS: 1,
    SUCCESS: 2,
    FAILURE: 3,
};

const createAPI = (ID, URL, METHOD) => ({
    ID,
    URL,
    METHOD,
});

const createAPISet = (NAME, API) => {
    let result = {};
    result[`GET_${NAME}_LIST`] = createAPI(
        `GET_${NAME}_LIST`,
        `/${API}/`,
        HTTP_METHOD.GET
    );
    result[`GET_${NAME}`] = createAPI(
        `GET_${NAME}`,
        `/${API}/{0}/`,
        HTTP_METHOD.GET
    );
    result[`CREATE_${NAME}`] = createAPI(
        `CREATE_${NAME}`,
        `/${API}/`,
        HTTP_METHOD.POST
    );
    result[`UPDATE_${NAME}`] = createAPI(
        `UPDATE_${NAME}`,
        `/${API}/{0}/`,
        HTTP_METHOD.PUT
    );
    result[`DELETE_${NAME}`] = createAPI(
        `DELETE_${NAME}`,
        `/${API}/{0}/`,
        HTTP_METHOD.DELETE
    );
    return result;
};

export const API_LIST = {
    GET_THREAT_LIST_DATA: createAPI(
        "GET_THREAT_LIST_DATA",
        "/admin/threat_lists/",
        HTTP_METHOD.GET
    ),
    DETAIL_THREAT_LIST: createAPI(
        "DETAIL_THREAT_LIST",
        "/admin/threat_lists/{0}/",
        HTTP_METHOD.GET
    ),
    UPDATE_THREAT_LIST_DATA: createAPI(
        "UPDATE_THRAET_LSIT_DATA",
        `/admin/threat_lists/{0}/`,
        HTTP_METHOD.PUT
    ),
    CREATE_THREAT_LIST_DATA: createAPI(
        "CREATE_THRAET_LSIT_DATA",
        `/admin/threat_lists/`,
        HTTP_METHOD.POST
    ),
    IMPORT_FROM_FILE_THREAT_ITEMS: createAPI(
        "IMPORT_FROM_FILE_THREAT_ITEMS",
        `/admin/threat_lists/{0}/import_from_file/`,
        HTTP_METHOD.POST
    ),
    LIST_THREAT_ITEMS: createAPI(
        "LIST_THREAT_ITEMS",
        "/admin/threat_items/",
        HTTP_METHOD.GET
    ),
    DELETE_THREAT_LIST_DATA: createAPI(
        "DELETE_THRAET_LSIT_DATA",
        `/admin/threat_lists/{0}/`,
        HTTP_METHOD.DELETE
    ),
    GET_ACCOUNT_DATA: createAPI("GET_ACCOUNT_DATA", "/account/", HTTP_METHOD.GET),
    GET_DEVICE_TRACK_DATA: createAPI(
        "GET_DEVICE_TRACK_DATA",
        "/admin/device-track/",
        HTTP_METHOD.POST
    ),
    LOGIN: createAPI("LOGIN", "/auth/admin_login/", HTTP_METHOD.POST),
    LOGOUT: createAPI("LOGOUT", "/auth/logout/", HTTP_METHOD.POST),
    SEND_RECOVERY_MAIL: createAPI(
        "SEND_RECOVERY_MAIL",
        "/account/admin_send_recovery_mail/",
        HTTP_METHOD.POST
    ),
    PASSWORD_RESET: createAPI(
        "PASSWORD_RESET",
        "/account/password_reset/",
        HTTP_METHOD.POST
    ),
    LOAD_CONFIGURATION: createAPI(
        "LOAD_CONFIGURATION",
        "/admin/configuration/",
        HTTP_METHOD.GET
    ),
    UPDATE_CONFIGURATION: createAPI(
        "UPDATE_CONFIGURATION",
        "/admin/configuration/",
        HTTP_METHOD.PUT
    ),
    ...createAPISet("USER", "users"),
};
