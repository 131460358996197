
const SET_DEVICE_TRACK_FORM_FIELD = 'SET_DEVICE_TRACK_FORM_FIELD'

export const setDeviceTrackFormFieldAction = (field, value) => ({
  type: SET_DEVICE_TRACK_FORM_FIELD,
  payload: { field, value }
})

const initialState = {
  formData: {
    firstName: "",
    lastName: "",
    email: "",
    device: "",
    ip: "",
    recordStart: "",
    recordEnd: ""
  }
}

export default function uiDeviceTrackReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_TRACK_FORM_FIELD:
      const { formData } = state
      return {
        ...state,
        formData: {
          ...formData,
          [action.payload.field]: action.payload.value,
        }
      }
    default:
      return state
  }
}