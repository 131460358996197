const SET_THREAT_LISTS = 'SET_THREAT_LISTS'
const SET_THREAT_LIST = "SET_THREAT_LIST"
const REMOVE_THREAT_LIST = "REMOVE_THREAT_LIST"


export const  setThreatListsAction = threatLists => ({
  type: SET_THREAT_LISTS,
  payload: {
    threatLists,
  }
})


export const setThreatListAction = threatList => ({
    type: SET_THREAT_LIST,
    payload: {
        threatList,
    }
})


export const deleteThreatListAction = threatListId => ({
    type: REMOVE_THREAT_LIST,
    payload: {
        threatListId,
    }
})


const initialState = []


export default function entityThreatListReducer(state = initialState, action) {

  let newState = [...state]

  switch (action.type) {
    case SET_THREAT_LISTS:
        return action.payload.threatLists;

    case SET_THREAT_LIST:
        const update_index = state.findIndex( 
                (threatList) => threatList.id === action.payload.threatList.id
        )
        if (update_index === -1){
                newState.push(action.payload.threatList)
        }else{
            newState[update_index] = action.payload.threatList
        }

        return newState

    case REMOVE_THREAT_LIST:
        return state.filter( 
                (threatList) => threatList.id !== action.payload.threatListId
        )


    default:
      return state
  }
}
