import { API_ROOT, API_CALL_STATE } from "./api";
import { toast, Bounce, ToastPosition, ToastType } from "react-toastify";

export const history = require("history").createHashHistory();

export const generateApiUrl = (url, urlParams) => {
  if (!Array.isArray(urlParams)) {
    urlParams = [urlParams];
  }

  for (let i = 0; i < urlParams.length; ++i) {
    url = url.replace(`{${i}}`, urlParams[i]);
  }

  return API_ROOT + url;
};

export const getURLParameters = (url) => {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export const getNormalizedData = (list) => {
  let result = {
    ids: [],
    byId: {},
  };

  if (true !== Array.isArray(list)) return result;

  list.forEach((item) => {
    if (undefined !== item.id) {
      result.ids.push(item.id);
      result.byId[item.id] = item;
    }
  });

  return result;
};

export const checkUIBlock = (apiState) => {
  if (undefined === apiState) return false;

  if (API_CALL_STATE.PROCESS === apiState.state) return true;

  return false;
};

export const mergeNormalizedData = (data1, data2) => {
  let result = JSON.parse(JSON.stringify(data1));
  data2.ids.forEach((id) => {
    if (result.ids.indexOf(id) < 0) result.ids.push(id);

    result.byId[id] = data2.byId[id];
  });
  return result;
};

export const isEmptyString = (str) => {
  return undefined === str || "" === str.toString();
};

export const hasEmptyValue = (arr) => {
  if (!Array.isArray(arr)) return false;

  let result = false;

  arr.forEach((x) => {
    if (isEmptyString(x)) {
      result = true;
    }
  });
  return result;
};

const showToast = (type, msg) => {
  toast(msg, {
    transition: Bounce,
    closeButton: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    autoClose: 5000,
    position: ToastPosition.BOTTOM_CENTER,
    type: type,
  });
};

export const showError = (msg) => showToast(ToastType.ERROR, msg);
export const showSuccess = (msg) => showToast(ToastType.SUCCESS, msg);
export const showWarning = (msg) => showToast(ToastType.WARNING, msg);
export const showInformation = (msg) => showToast(ToastType.INFO, msg);
