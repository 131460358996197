import axios from 'axios';

const ACCOUNT_REFRESH_BY_ACCESS_TOKEN = 'ACCOUNT_REFRESH_BY_ACCESS_TOKEN'
const ACCOUNT_LOGIN = 'ACCOUNT_LOGIN'
const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'

export const accountLoginAction = (accessToken, accountData) => ({
  type: ACCOUNT_LOGIN,
  payload: { accessToken, accountData }
})

export const accountRefreshByAccessTokenAction = (accountData) => ({
  type: ACCOUNT_REFRESH_BY_ACCESS_TOKEN,
  payload: { accountData }
})

export const accountLogoutAction = () => ({
  type: ACCOUNT_LOGOUT,
})


const loadAccessToken = () => {
  let accessToken = localStorage.getItem("adminAccessToken")
  if (null !== accessToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`
  } else {
    accessToken = undefined
  }

  return accessToken
}

const saveAccessToken = accessToken => {
  if (undefined !== accessToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`
    localStorage.setItem("adminAccessToken", accessToken)
  } else {
    axios.defaults.headers.common['Authorization'] = undefined
    localStorage.removeItem("adminAccessToken")
  }
}


const initialState = {
  accessToken: loadAccessToken(),
  isAuthenticated: false,
  accountData: {},
}

export default function entityAccountReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LOGIN: {
      saveAccessToken(action.payload.accessToken)
      return {
        ...state,
        accessToken: action.payload.accessToken,
        accountData: action.payload.accountData,
        isAuthenticated: true,
      }
    }
    case ACCOUNT_LOGOUT: {
      saveAccessToken(undefined)
      return {
        ...state,
        accessToken: undefined,
        accountData: {},
        isAuthenticated: false,
      }
    }
    case ACCOUNT_REFRESH_BY_ACCESS_TOKEN: {
      return {
        ...state,
        accountData: action.payload.accountData,
        isAuthenticated: true,
      }
    }
    default:
      return state
  }
}