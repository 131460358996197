import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    ToastContainer,
} from 'react-toastify';
import { apiLoadAppDataAction } from '../../sagas/apiCallSaga';
import LoggedInRoute from './Components/LoggedInRoute';

const UserPages = lazy(() => import('../../DemoPages/UserPages'));
const AdminPages = lazy(() => import('../../DemoPages/AdminPages'));
const SettingPages = lazy(() => import('../../DemoPages/SettingPages'));
{/*const Applications = lazy(() => import('../../DemoPages/Applications'));
const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));

const Widgets = lazy(() => import('../../DemoPages/Widgets'));
const Elements = lazy(() => import('../../DemoPages/Elements'));
const Components = lazy(() => import('../../DemoPages/Components'));
const Charts = lazy(() => import('../../DemoPages/Charts'));
const Forms = lazy(() => import('../../DemoPages/Forms'));
const Tables = lazy(() => import('../../DemoPages/Tables'));*/}

const mapStateToProps = store => ({
    account: store.entity.account
})

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        loadAppData: apiLoadAppDataAction,
    }, dispatch)
)

class AppMain extends Component {

    componentDidMount() {
        let { accessToken } = this.props.account

        if (undefined === accessToken) return

        this.props.loadAppData()
    }

    render() {

        let { isAuthenticated, accessToken } = this.props.account

        return (
            <Fragment>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait until main pages are loaded...
                            </h6>
                        </div>
                    </div>
                }>
                    <LoggedInRoute path="/admin" component={AdminPages}/>
                </Suspense>

                {/* Setting Pages */}

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait until setting pages are loaded...
                            </h6>
                        </div>
                    </div>
                }>
                    <LoggedInRoute path="/settings" component={SettingPages}/>
                </Suspense>

                {/* Pages */}

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="line-scale-party"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Pages examples
                                <small>Because this is a demonstration we load at once all the Pages examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/pages" component={UserPages}/>
                </Suspense>

                <Route exact path="/" render={() => ( isAuthenticated ?
                    <Redirect to="/admin/dashboard"/> :
                    undefined === accessToken ?
                    <Redirect to="/pages/login-boxed"/> :
                    <h2>Loading...</h2>
                )}/>

                <ToastContainer/>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMain);