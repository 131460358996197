import ThemeOptions from "./ThemeOptions";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import apiCallReducer from "./apiCall";
import uiLoginReducer from "./ui/login";
import uiUsersReducer from "./ui/users";
import uiPasswordResetReducer from "./ui/passwordReset";
import uiForgotPasswordReducer from "./ui/forgotPassword";
import entityAccountReducer from "./entity/account";
import entityUsersReducer from "./entity/users";
import uiDeviceTrackReducer from "./ui/deviceTrack";
import appDataReducer from "./appData";
import entityConfigurationReducer from "./entity/configuration";
import uiConfigurationReducer from "./ui/configuration";
import entityThreatListReducer from "./entity/threatList"
import entityThreatItemReducer from "./entity/threatItems"

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  ThemeOptions,
  router: connectRouter(history),
  apiCallData: apiCallReducer,
  appData: appDataReducer,
  entity: combineReducers({
    account: entityAccountReducer,
    users: entityUsersReducer,
    configuration: entityConfigurationReducer,
    threatLists: entityThreatListReducer,
    threatItems: entityThreatItemReducer,
  }),
  ui: combineReducers({
    login: uiLoginReducer,
    users: uiUsersReducer,
    deviceTrack: uiDeviceTrackReducer,
    configuration: uiConfigurationReducer,
    passwordReset: uiPasswordResetReducer,
    forgotPassword: uiForgotPasswordReducer,
  }),
});

export default rootReducer;
